<template>
  <div class="flex mt-[0.75rem] w-full">
    <div class="w-full flex flex-col justify-center text pl-[0.75rem] bg-white h-[2.875rem] rounded-[0.5rem]">
      <div class="flex items-center justify-between">
        <div>
          <span class="text !text-[#3C4549]">{{ getSaveLink.domain.url }}</span>
          <span class="text !text-[#3C4549]" v-if="!edit_slug">{{getSaveLink.shorten_url}}</span>
          <input v-else class="p-0 border-0" ref="editSlugField" v-model="slugValue" >
          <input type="hidden" id="copy-url"
                 :value="copyUrl(getSaveLink.shorten_url,getSaveLink.domain.url)">
        </div>
        <div class="flex items-center justify-center">
          <div :class="{'px-2': edit_slug}" class=" flex items-center gap-x-2">
            <svg v-if="!edit_slug" @click.prevent="editQuickLinkSlug()" xmlns="http://www.w3.org/2000/svg" class="w-4 cursor-pointer h-4 mx-[0.75rem]" viewBox="0 0 18 18" fill="none">
              <path d="M12.8969 1.70398C13.3477 1.25323 13.959 1 14.5965 1C15.2339 1 15.8453 1.25323 16.296 1.70398C16.7468 2.15473 17 2.76608 17 3.40354C17 4.041 16.7468 4.65234 16.296 5.1031L5.53215 15.867L1 17L2.13304 12.4678L12.8969 1.70398Z" stroke="#4D4D4D" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
            <svg v-if="edit_slug" @click="editSlugForQuickLink()" xmlns="http://www.w3.org/2000/svg" class="w-3.5 h-3.5 cursor-pointer" viewBox="0 0 17 12" fill="none">
              <path d="M15.5455 1L5.54545 11L1 6.45455" stroke="#2961D2" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
            <svg v-if="edit_slug" @click="edit_slug = false" xmlns="http://www.w3.org/2000/svg" class="w-3 h-3 cursor-pointer" viewBox="0 0 12 12" fill="none">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M10.816 2.24461C11.1089 1.95172 11.1089 1.47684 10.816 1.18395C10.5231 0.891056 10.0482 0.891056 9.75533 1.18395L5.99995 4.93933L2.24456 1.18395C1.95167 0.891056 1.4768 0.891056 1.1839 1.18395C0.89101 1.47684 0.89101 1.95172 1.1839 2.24461L4.93929 5.99999L1.1839 9.75538C0.89101 10.0483 0.89101 10.5231 1.1839 10.816C1.4768 11.1089 1.95167 11.1089 2.24456 10.816L5.99995 7.06065L9.75533 10.816C10.0482 11.1089 10.5231 11.1089 10.816 10.816C11.1089 10.5231 11.1089 10.0483 10.816 9.75538L7.06061 5.99999L10.816 2.24461Z" fill="#C13A52"/>
            </svg>
          </div>
          <Button
            id="edit-url-slug"
            type="button"
            class="text-white !rounded-tl-none !rounded-bl-none px-[1rem] h-[2.875rem] bg-[#3C4549]"
            @click="copyUrlName"
          >
            <template v-slot:label>Copy Link</template>
          </Button>
        </div>
      </div>
    </div>
    <div v-tooltip.top-center="'Download QR Code'" @click.prevent="generateQRCode()" class="border w-[2.8rem] ml-[0.5rem] flex items-center justify-center bg-white cursor-pointer border-[#3C4549] rounded-[0.5rem] p-[0.563rem]">
      <svg v-if="!qrLoader" xmlns="http://www.w3.org/2000/svg" class="w-[1.5rem] h-[1.5rem]" viewBox="0 0 24 24" fill="none">
        <path d="M1.37143 0C1.0077 0 0.658875 0.14449 0.401682 0.401682C0.14449 0.658875 0 1.0077 0 1.37143V6.85714H6.85714V0H1.37143ZM5.14286 5.14286H1.71429V1.71429H5.14286V5.14286Z" fill="#3C4549"/>
        <path d="M0 22.6283C0 22.992 0.14449 23.3408 0.401682 23.598C0.658875 23.8552 1.0077 23.9997 1.37143 23.9997H6.85714V17.1426H0V22.6283ZM1.71429 18.8569H5.14286V22.2854H1.71429V18.8569Z" fill="#3C4549"/>
        <path d="M17.1428 23.9997H22.6285C22.9923 23.9997 23.3411 23.8552 23.5983 23.598C23.8555 23.3408 24 22.992 24 22.6283V17.1426H17.1428V23.9997ZM18.8571 18.8569H22.2857V22.2854H18.8571V18.8569Z" fill="#3C4549"/>
        <path d="M22.6285 0H17.1428V6.85714H24V1.37143C24 1.0077 23.8555 0.658875 23.5983 0.401682C23.3411 0.14449 22.9923 0 22.6285 0ZM22.2857 5.14286H18.8571V1.71429H22.2857V5.14286Z" fill="#3C4549"/>
        <path d="M13.7142 5.14202V3.42773H10.2856V6.85631H11.9999V5.14202H13.7142Z" fill="#3C4549"/>
        <path d="M6.85718 6.85742H8.57146V8.57171H6.85718V6.85742Z" fill="#3C4549"/>
        <path d="M8.57153 8.57227H12.0001V10.2866H8.57153V8.57227Z" fill="#3C4549"/>
        <path d="M13.7144 1.71429V3.42857H15.4287V0H8.57153V3.42857H10.2858V1.71429H13.7144Z" fill="#3C4549"/>
        <path d="M0 8.57227H1.71429V12.0008H0V8.57227Z" fill="#3C4549"/>
        <path d="M6.85714 10.2866V12.0008H5.14286V8.57227H3.42857V12.0008H1.71429V13.7151H0V15.4294H3.42857V13.7151H5.14286V15.4294H6.85714V13.7151H8.57143V10.2866H6.85714Z" fill="#3C4549"/>
        <path d="M13.7143 10.2854H15.4286V11.9997H17.1429V10.2854H18.8571V8.57115H15.4286V5.14258H13.7143V6.85686H12V8.57115H13.7143V10.2854Z" fill="#3C4549"/>
        <path d="M12.0001 22.2866H8.57153V24.0008H15.4287V22.2866H13.7144V20.5723H12.0001V22.2866Z" fill="#3C4549"/>
        <path d="M15.4285 13.7137V11.9994H13.7142V10.2852H11.9999V11.9994H10.2856V13.7137H11.9999V15.428H13.7142V13.7137H15.4285Z" fill="#3C4549"/>
        <path d="M22.2856 13.7148H23.9999V15.4291H22.2856V13.7148Z" fill="#3C4549"/>
        <path d="M15.4285 13.7148H20.5713V15.4291H15.4285V13.7148Z" fill="#3C4549"/>
        <path d="M22.2857 8.57227H20.5715V10.2866H18.8572V12.0008H20.5715V13.7151H22.2857V12.0008H24V10.2866H22.2857V8.57227Z" fill="#3C4549"/>
        <path d="M13.7144 15.4277H15.4286V20.5706H13.7144V15.4277Z" fill="#3C4549"/>
        <path d="M8.57153 20.572H10.2858V18.8577H12.0001V17.1434H10.2858V13.7148H8.57153V20.572Z" fill="#3C4549"/>
      </svg>
      <Loader color="text-[#2560d7]" v-else></Loader>
    </div>
  </div>
</template>
<script>
import { slugMessages } from '@/common/attributes'
import { mapActions, mapGetters } from 'vuex'

export default {
  data() {
    return {
      edit_slug: false,
      oldSlug: '',
      slugValue: '',
      qrLoader: false
    }
  },
  components: {
    Button: () => import('@/ui/ui-kit/v2/Button.vue'),
    Loader: () => import('@/ui/ui-kit/v2/Loader.vue')
  },
  computed: {
    ...mapGetters(['getSaveLink']),
  },
  methods: {
    ...mapActions(['createQRCode']),
    async generateQRCode() {
      this.qrLoader = true
      await this.createQRCode({url: this.getDomainURl(this.getSaveLink), slug: this.getSaveLink.shorten_url})
      this.qrLoader = false
    },
    getDomainURl (link) {
      return link.domain ? link.domain.url+link.shorten_url : link.domain.url+link.shorten_url
    },
    async editSlugForQuickLink () {
      this.edit_slug = false
      if (!this.slugValue && this.slugValue == '') {
        this.getSaveLink.shorten_url = this.oldSlug
        this.slugValue = this.oldSlug
        this.alertMessage('Slug value could not be empty', 'error')
        return
      }

      if (this.checkSlugLength(this.slugValue)) {
        this.alertMessage(slugMessages.slug_length, 'error')
        return
      }

      if (this.getSaveLink.shorten_url === this.slugValue) return

      this.getSaveLink.shorten_url = this.slugValue
      let res = await this.$store.dispatch('updateSlug')

      if (res.data.status) {
        this.$store.dispatch('toastNotification', { message: 'Your link slug has been updated' })
        this.oldSlug = this.getSaveLink.shorten_url
        this.slugValue = this.getSaveLink.shorten_url
        return
      }

      this.$store.dispatch('toastNotification', { message: res.data.message, type: 'error' })

      this.getSaveLink.shorten_url = this.oldSlug
      this.slugValue = this.oldSlug

    },
    // create quick link methods for editing slug
    editQuickLinkSlug () {
      this.edit_slug = true
      this.slugValue = this.getSaveLink.shorten_url
      if (!this.oldSlug) this.oldSlug = this.getSaveLink.shorten_url
      setTimeout(() => {
        this.$refs.editSlugField.focus()
      })
    },
    copyUrlName () {
      let testingCodeToCopy = document.querySelector('#copy-url')
      testingCodeToCopy.setAttribute('type', 'text') // 不是 hidden 才能複製
      testingCodeToCopy.select()
      try {
        var successful = document.execCommand('copy')
        var msg = successful ? 'successful' : 'unsuccessful'
        this.onCopy()
      } catch (err) {
        this.onError()
      }

      /* unselect the range */
      testingCodeToCopy.setAttribute('type', 'hidden')
      window.getSelection().removeAllRanges()
    },
  }
}
</script>
